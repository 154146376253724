.profile_card {
    border-radius: 5px;
    padding-top: 15px;
    padding-bottom: 25px;
    margin-top: 10px;
}

.profile_name {
    margin-top: 5px;
    font-weight: 600;
    font-size: 20px;
    color: #012970;
}

.designation {
    margin-top: -10px;
    font-weight: 500;
    color: rgb(101, 101, 101);
}

.details_card {
    margin-top: 15px;
    margin-bottom: 40px;
    border-radius: 5px;
    border: 2px solid #bbc2ff;
    padding-top: 15px;
    padding-bottom: 15px;
}

.profile_image {
    height: 150px;
    width: 150px;
    border-radius: 500px;
    border: 5px solid #808dff;
    cursor: pointer;
}

.basic_details_heading {
    margin-left: 18px;
    font-weight: 900;
    color: #4154f1;
    font-size: 28px;
    font-variant-caps: all-petite-caps;
}


.activity_list {
    list-style-type: disc;
    color: #012970;
    margin-left: 10px;
}

.activity_card {
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 1px;
    margin-right: 15px;
    border-radius: 6px;
}

.ok_card {
    margin-bottom: 40px;
    margin-top: 15px;
    border-radius: 5px;
    border: 2px solid #bbc2ff;
    padding-top: 15px;
    padding-bottom: 15px;
}

.assigned_card {
    background-color: rgb(211, 232, 255);
    border: 1px solid rgb(151, 202, 255);
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 1px;
    margin-right: 15px;
    margin-left: 15px;
    margin-top: 15px;
    border-radius: 6px;
}

.pending_card {
    background-color: rgb(221, 251, 238);
    border: 1px solid rgb(151, 255, 208);
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 1px;
    margin-right: 15px;
    margin-left: 15px;
    margin-top: 15px;
    border-radius: 6px;
}

.assigned_p {
    font-size: 13px;
}

.goBackButton {
    height: 35px;
    padding: 0px 10px;
    border-radius: 5px;
    margin-left: 18px;
    color: white;
    background-color: #4154f1;
    border: none;
}

.assignButton {
    height: 35px;
    padding: 0px 25px;
    border-radius: 5px;
    color: white;
    background-color: #2fa06b;
    border: none;
    margin-left: 20px;
}

.invContainer {
    border: 1px solid rgb(255, 191, 113);
    background-color: antiquewhite;
    border-radius: 5px;
    margin-bottom: 8px;
}

.assetViewButton {
    border: 1px solid rgb(255, 191, 113);
    background-color: bisque;
    border-radius: 5px;
}

.assetKaViewButton {
    border: 1px solid rgb(86, 168, 255);
    background-color: rgb(145, 198, 255);
    border-radius: 5px;
    padding: 10px 15px;
    margin-top: -10px;
}

.pendingKaViewButton {
    border: 1px solid rgb(61, 255, 203);
    background-color: rgb(172, 255, 233);
    border-radius: 5px;
    padding: 10px 15px;
    margin-top: -10px; 
}

.view_row {
    margin-bottom: 50px;
}

.edit_asset_button {
    padding: 1px 25px;
    border-radius: 5px;
    border: none;
    background-color: #008b8b;
    color: white;
    border: 2px solid #008b8b;
}

.edit_asset_button:hover {
    background-color: white;
    color: #008b8b;
    border: 2px solid #00588b;
    font-weight: 500;
}

.edit_icon {
    padding-left: 10px;
    font-size: 25px;
    color: #0099ff;
    cursor: pointer;
}

.modal_footer {
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: -15px;
}

.custom_modal_input {
    padding: 5px 10px;
    margin-bottom: 10px;
}

.custom_modal_input2 {
    padding: 10px 10px;
    margin-bottom: 10px;
    border: 1px solid #4154f1;
}

.blue_input {
    border: 1px solid #4154f1;
}

.asset_document_download {
    color: rgb(86, 168, 255);
}

.asset_document_download:hover {
    cursor: pointer;
    color: rgb(121, 84, 255);
}

.custom_footer-blue {
    border-bottom: none;
}

.custom_footer_modal {
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 20px;
    margin-top: -30px;
}

.custom_blue-row {
    border: 1px solid rgb(121, 84, 255);
    padding: 5px 0px;
    margin: 5px 0px 5px 0px;
    border-radius: 5px;
}

.invoice_p {
    margin-bottom: 0px;
}

.cross_icon {
    color: red;
    cursor: pointer;
}

.cross_icon:hover {
    color: rgb(108, 0, 0);
}

.edit_profile_button {
    height: 32px;
    width: 110px;
    background-color: rgb(121, 84, 255);
    border: 1px solid rgb(121, 84, 255);
    color: #fff;
    border-radius: 5px;
}

.edit_profile_button:hover {
    background-color: #fff;
    border: 2px solid rgb(121, 84, 255);
    color: rgb(121, 84, 255);
    font-weight: 600;
}

.edit_profile_cancel {
    height: 32px;
    width: 110px;
    background-color: rgb(255, 25, 75);
    border: 1px solid rgb(255, 25, 75);
    color: #fff;
    border-radius: 5px;
}
.edit_profile_cancel:hover {
    background-color: #fff;
    border: 2px solid rgb(255, 25, 75);
    color: rgb(255, 25, 75);
    font-weight: 600;
}

.activityLink {
    color:rgb(153, 0, 255);
    font-weight: 600;
    cursor: pointer;
}