.full_div {
  box-shadow: 2cap;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding-top: 20px;
  padding-bottom: 60px;
}

.custom-loader {
  width: 50px;
  height: 50px;
  --c: radial-gradient(farthest-side, #766DF4 92%, #0000);
  background:
    var(--c) 50% 0,
    var(--c) 50% 100%,
    var(--c) 100% 50%,
    var(--c) 0 50%;
  background-size: 12px 12px;
  background-repeat: no-repeat;
  animation: s7 1s infinite;
}

@keyframes s7 {
  to {
    transform: rotate(.5turn)
  }
}

@media (min-width: 768px) {
  .mobile-hidden {
    display: none;
  }
}