.overall_div {
    box-shadow: 2cap;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding-top: 20px;
    padding-bottom: 60px;
}
.h3_addAsset {
    padding-top: 10px;
    font-weight: 800;
    color: #012970;
}
.input_div {
    margin: 7px 0px;
}
.form_row {
    margin-bottom: 10px;
}
.heading2 {
    margin-top: 20px;
    font-size: 23px;
    font-weight: 900;
    color: #012970;
}
.add_more_btn {
    height: 40px;
    padding: 5px;
    color: #ffffff;
    background-color: rgb(106, 93, 254);
}
.remove_field_btn {
    margin-top: 8px;
    padding: 5px;
    height: 57px;
    background-color: #ff4281;
}
.remove_field_btn:hover {
    background-color: red;
}
.add_more_btn {
    margin-top: 8px;
    padding: 5px;
    height: 57px;
}
.submit_btn {
    margin-top: 39px;
    padding: 5px;
    height: 37px;
}
.cancel_btn {
    margin-top: 39px;
    padding: 5px;
    height: 37px;
    background-color: white;
    border: 2px solid #012970;
    color: #012970;
}
.cancel_btn:hover {
    color: white;
    background-color: #012970;
}

.form-control {
  padding: 0px;
}
